
import FileUploadInput from "../../file-upload-input.vue";

export default {
    name: "SheetBook",
    components: {FileUploadInput},
    props: {
        bulkCompanies: {
            type: Array,
            default: () => [],
        },
        event: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            downloadRequestFinished: false,
            permissionError: false,
            downloadUrl: null,
            promise: null,
            requestData: {
                companyIds: this.bulkCompanies.map(el => el.id),
                format: "pdf",
                frontPage: true,
                eventId: this.event ? this.event.id : null,
                sections: {
                    managementAndBoard: true,
                    investmentSummary: true,
                    driversAndRisks: true,
                    milestones: true,
                    atmInfo: true,
                    pressReleases: true,
                    investors: true,
                    holders: true,
                    holdings: true,
                    projections: true,
                    peerGroup: true,
                    expandedPeerGroup: false,
                    sessionsMeetingRequests: true,
                    institutionalEngagement: true,
                },
            },

            sectionLabels: {
                managementAndBoard: "Management and Board",
                investmentSummary: "Investment Summary",
                driversAndRisks: "Drivers and Risks",
                milestones: "Milestones",
                atmInfo: "ATM Info",
                pressReleases: "Press Releases",
                investors: "Investors",
                holders: "Holders",
                holdings: "Holdings",
                projections: "Projections",
                peerGroup: "Peer Group",
                expandedPeerGroup: "Extended Peer Group",
                sessionsMeetingRequests: "Sessions Meeting Requests",
                institutionalEngagement: "Institutional Engagement",
            }
        }
    },
    computed: {
        formatOptions() {
            const options = [];
            options.push({value: 'pdf', label: 'PDF'});
            if (this.isUserWithRoleResearchAnalyst) {
                options.push({value: 'docx', label: 'Word'});
            }

            return options;
        },
    },
    methods: {
        run() {
            this.promise = new Promise((resolve, reject) => {
                this.$emit("loading", true);
                this.$axios.post('/api/company_sheets/book', this.requestData)
                    .then((response) => {
                        this.loading = false;
                        this.$emit("loading", false);
                        this.downloadUrl = response.data.url;
                    })
                    .catch((error) => {
                        this.permissionError = error.response.status === 401 || error.response.status === 403;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.downloadRequestFinished = true;
                        this.$emit("loading", false);
                        this.$emit("finished", true);
                        resolve({stayOnPage: true});
                    });
            });

            return this.promise;
        },
        reset() {
            this.downloadRequestFinished = false;
            this.loading = false;
            this.$emit("finished", false);
            this.$emit("loading", false);
            this.requestData.sections = {
                managementAndBoard: true,
                investmentSummary: true,
                driversAndRisks: true,
                milestones: true,
                atmInfo: true,
                pressReleases: true,
                investors: true,
                holders: true,
                holdings: true,
                projections: true,
                peerGroup: true,
                expandedPeerGroup: false,
                sessionsMeetingRequests: true,
                institutionalEngagement: true,
            };
        },
    }
}
