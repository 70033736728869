

import moment from "moment";
import {mapGetters} from "vuex";
import modals from "../../mixins/modals";
import LoadingSpinner from "../loading-spinner";
import UserCoverageModal from "../users/user-coverage-modal";
import TableFilters from "../table-filters";
import EditNoteModal from "../notes/edit-note-modal";
import CompanyFeedbackModal from "../events/company-feedback-modal";
import Gmap from "../helpers/gmap";
import FavStar from "../fav-star";
import RedFlag from "../red-flag";
import fileDownload from "../../mixins/file-download";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import {OPTION_COMPANY, OPTION_FUND, OPTION_OTHER} from "../../mixins/company-classification";
import DownloadSelectColumnsModal from "../download-select-columns-modal.vue";
import eventCompanyTicker from "../events/event-company-ticker.vue";
import staffing from "../../mixins/staffing";
import Restricted from "../restricted.vue";
import AddCompanyModal from "./add-company-modal";
import EditDetailsModal from "./edit-details-modal";
import CompanyBulkOperationModal from "./company-bulk-operation-modal";
import DeleteConfirmModal from "./delete-confirm-modal";
import CompanyMarketCapCell from "./companies-table-cells/company-market-cap-cell";
import CompanyFeedbackCell from "./companies-table-cells/company-feedback-cell";
import CompanyPriceCell from "./companies-table-cells/company-price-cell";
import CompanyTickerCell from "./companies-table-cells/company-ticker-cell";
import CompanyBankerCell from "./companies-table-cells/company-banker-cell";
import CompanyAnalystCell from "./companies-table-cells/company-analyst-cell";
import CompanyChangeCell from "./companies-table-cells/company-change-cell";
import CompanyPortraitCell from "./companies-table-cells/company-portrait-cell";
import CompanyResearchReportCell from "./companies-table-cells/company-research-report-cell";
import RemoveFromEventModal from "./remove-from-event-modal.vue";
import CompanyMarketListMembersCell from "./companies-table-cells/company-market-list-members-cell";
import CopyInformationModal from "./copy-information-modal.vue";
import CompanyAgendaCell from "./companies-table-cells/company-agenda-cell";
import TableActions from "~/components/table-actions";
import ConfirmModal from "~/components/confirm-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";
import RequestStaffingModal from "~/components/company/request-staffing-modal";
import PushCompaniesToSessionsModal from "~/components/company/push-companies-to-sessions-modal";

export default {
    name: 'CompaniesTable',
    components: {
        Restricted,
        CopyInformationModal,
        DownloadSelectColumnsModal,
        QuintableSearchInput,
        RemoveFromEventModal,
        CompanyResearchReportCell,
        CompanyPortraitCell,
        CompanyChangeCell,
        CompanyAnalystCell,
        CompanyBankerCell,
        CompanyTickerCell,
        CompanyPriceCell,
        CompanyFeedbackCell,
        CompanyMarketCapCell,
        RedFlag,
        FavStar,
        CompanyBulkOperationModal,
        TableFilters,
        UserCoverageModal,
        LoadingSpinner,
        AddCompanyModal,
        ConfirmModal,
        EditNoteModal,
        TableActions,
        EditDetailsModal,
        CompanyFeedbackModal,
        Gmap,
        DeleteConfirmModal,
        RequestStaffingModal,
        CompanyMarketListMembersCell,
        eventCompanyTicker,
        CompanyAgendaCell,
        PushCompaniesToSessionsModal
    },
    mixins: [optionButtonTooltip, modals, fileDownload, staffing],
    props: {
        openedFilter: {
            type: Boolean,
            default: false
        },
        event: {
            type: Object,
            default: null,
        },
        showFilters: {
            type: Boolean,
            required: false,
            default: true,
        },
        enableSelect: {
            type: Boolean,
            required: false,
            default: true,
        },
        showAdd: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDownload: {
            type: Boolean,
            required: false,
            default: true,
        },
        showAddNote: {
            type: Boolean,
            required: false,
            default: true,
        },
        showRequestStaffing: {
            type: Boolean,
            required: false,
            default: true,
        },
        showRequestDeleteStaffing: {
            type: Boolean,
            required: false,
            default: true,
        },
        showEdit: {
            type: Boolean,
            required: false,
            default: true,
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: true,
        },
        showRemoveFromEvent: {
            type: Boolean,
            required: false,
            default: false,
        },
        showExtendedFinancialInfo: {
            type: Boolean,
            required: false,
            default: false,
        },
        allowAddFeedback: {
            type: Boolean,
            required: false,
            default: true,
        },
        userId: {
            type: Number,
            default: null
        },
        relevantForUserId: {
            type: Number,
            default: null,
        },
        highlightOtherCompanies: {
            type: Boolean,
            required: false,
            default: false,
        },
        highlightMyCompanies: {
            type: Boolean,
            required: false,
            default: false,
        },
        highlightPressReleases: {
            type: Boolean,
            default: false,
        },
        indicateStaffing: {
            type: Boolean,
            default: false,
        },
        showFavStar: {
            type: Boolean,
            default: false,
        },
        showRedFlag: {
            type: Boolean,
            default: true,
        },
        showIsRestricted: {
            type: Boolean,
            default: true,
        },
        initialFilters: {
            type: Object,
            default: null
        },
        companyListId: {
            type: Number,
            default: null
        },
        identifier: {
            type: String,
            default: "company-table"
        },
        hideBankersInAdditionalRow: {
            type: Boolean,
            required: false,
            default: false,
        },
        showClassificationTabs: {
            type: Boolean,
            default: false,
        },
        companiesHash: {
            type: String,
            default: null,
        },
        pageSize: {
            type: Number,
            default: 50,
        },
        showRemoveFromList: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            // companies: [],
            searchQuery: "",
            companyToDelete: null,
            companyToRemoveFromEventId: null,
            companiesUpdated: moment().valueOf(),
            companyRequestStaffing: null,
            newCompany: null,
            modal: null,
            filterOptions: {
                companyRoles: [],
                exchanges: [],
                sectors: [],
                industries: [],
                companyCharacteristics: [],
                cities: [],
                states: [],
                countries: [],
                leadBankers: [],
                bankers: [],
                analysts: [],
                marketListMembers: [],
                corporateAccessManagers: [],
                eventRoles: [],
            },
            meetingWithContact: null,
            filter: {
                // Select filters:
                privateCompanies: this.relevantForUserId > 0 ? 0 : null,
                companyRoles: [],
                exchanges: [],
                sectors: [],
                industries: [],
                companyCharacteristics: [],
                cities: [],
                states: [],
                countries: [],
                leadBankers: [],
                bankers: [],
                analysts: [],
                marketListMembers: [],
                corporateAccessManagers: [],
                companyGroup: null,
                contactGroup: null,
                event: this.event ? this.event : null,
                eventRole: null,
                needsAttention: null,
                isRestricted: null,
                meetingWithContact: null,
                // Switch filters:
                onlyFavoriteCompanies: false,
                onlyOwnCompanies: false,
                leadBankerCompanies: false,
                secondaryBankerCompanies: false,
                onlyStaffedCompanies: false,
                onlyNotStaffedCompanies: false,
                onlyCoveredCompanies: false,
                onlyMarketListCompanies: false,
                excludeInactive: true,
                // Other filters:
                companyIds: null,
                area: null,
                // Tab filters:
                classification: null,
            },
            defaultFilter: null,
            privateCompanyOptions: [
                {
                    title: "Private",
                    value: 1,
                },
                {
                    title: "Public",
                    value: 0,
                }
            ],
            needsAttentionCompanyOptions: [
                {
                    title: "Only Companies Needing Attention",
                    value: 1,
                },
                {
                    title: "Exclude Companies Needing Attention",
                    value: 0,
                }
            ],
            isRestrictedCompanyOptions: [
                {
                    title: "Only Companies on Restricted List",
                    value: 1,
                },
                {
                    title: "Only Restricted Companies (ATM)",
                    value: 2,
                },
                {
                    title: "Only Restricted Companies (not ATM)",
                    value: 3,
                },
                {
                    title: "Exclude Companies on Restricted List",
                    value: 0,
                }
            ],
            mostUsedCompanyGroups: [],
            mostUsedContactGroups: [],
            addCompanyReset: 0,
            company: null,
            staffingLoading: null,
            coverageUserId: null,
            sortOrder: null,
            tableLoaded: false,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            mode: "filtered",
            relevantChangesHappened: false,
            ajaxRows: [],
            ajaxAll: 0,
            bulkUpdated: moment().valueOf(),
            bulkTriggered: false,
            bulkModal: null,
            noteToAdd: null,
            noteCompany: null,
            showTable: true,
            feedbackCellToEdit: null,
            OPTION_COMPANY,
            OPTION_FUND,
            OPTION_OTHER,
            downloadQuery: null,
            companyIdsForInfo: null,
            eventIdForInfo: null,
            pushToSessions: 0,
            companyRejectionInProgress: false,
        }
    },
    computed: {
        ...mapGetters({
            nextRoute: 'app/nextRoute',
        }),
        generatedSlotIdentifier() {
            return 'generated-cell-content';
        },
        slotIdentifier() {
            return 'cell-content';
        },
        config() {
            const params = {
                highlightOtherCompanies: this.highlightOtherCompanies,
                highlightMyCompanies: this.highlightMyCompanies,
                highlightPressReleases: this.highlightPressReleases,
            };
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    }, {
                        headline: "Ticker",
                        breakpoint: "sm",
                        sort: true
                    }, {
                        headline: "Mkt Cap",
                        breakpoint: "md",
                        sort: true,
                        align: "end",
                        firstSortDirection: "DESC",
                        hidden: !!this.event,
                    }, {
                        headline: "Cash Burn",
                        hidden: !this.showExtendedFinancialInfo
                    }, {
                        headline: "Cash and short term invest.",
                        hidden: !this.showExtendedFinancialInfo
                    }, {
                        headline: "AUM",
                        hidden: !this.showExtendedFinancialInfo
                    }, {
                        headline: "Price",
                        sort: this.userId || this.filter.onlyOwnCompanies || this.relevantForUserId,
                        firstSortDirection: "DESC",
                        hidden: !!this.event,
                    }, {
                        headline: "%/$ Chg",
                        sort: this.userId || this.filter.onlyOwnCompanies || this.relevantForUserId,
                        firstSortDirection: "DESC",
                        hidden: !!this.event,
                    }, {
                        headline: "$ Chg",
                        sort: this.userId || this.filter.onlyOwnCompanies || this.relevantForUserId,
                        hidden: true,
                        firstSortDirection: "DESC",
                    }, {
                        headline: 'Event Role',
                        breakpoint: 'xxl',
                        hidden: !this.event,
                    }, {
                        headline: 'Meetings',
                        breakpoint: 'xxl',
                        hidden: !this.event,
                    }, {
                        headline: 'Participants',
                        breakpoint: 'xxl',
                        hidden: !this.event,
                    }, {
                        headline: "Sector, Industry",
                        breakpoint: "xl",
                        sort: true,
                    }, {
                        headline: "City, State",
                        sort: true,
                        breakpoint: this.relevantForUserId ? 'all' : 'xl',
                    }, {
                        headline: "Cntry.",
                        breakpoint: "xl",
                        title: "Country",
                        sort: true,
                    },
                    {
                        headline: "Last Deal",
                        breakpoint: "xxl",
                        sort: true,
                        hidden: !!this.event,
                    },
                    {
                        headline: "My Last Meeting",
                        breakpoint: "xxl",
                        sort: true,
                        hidden: !!this.event,
                    },
                    this.isUserWithRoleBanker ? {
                        breakpoint: "xxl",
                        headline: "Rel.",
                        title: "Relationship",
                        sort: true,
                    } : null,
                    this.isUserWithRoleBanker ? {
                        headline: "Lead Banker",
                        breakpoint: this.hideBankersInAdditionalRow ? 'all' : 'xxl',
                    } : null,
                    this.isUserWithRoleBanker ? {
                        headline: "Banker",
                        breakpoint: this.hideBankersInAdditionalRow ? 'all' : 'xxl',
                    } : null,
                    {
                        headline: "Cov. Analysts",
                        breakpoint: "lg",
                    }, {
                        headline: "Market List Members",
                        breakpoint: "lg",
                    }, {
                        headline: 'Feedback',
                        breakpoint: "xxl",

                        hidden: !this.event,
                    },
                    {
                        headline: 'Agenda',
                        breakpoint: "xxl",
                        hidden: !this.event,
                    },
                    {
                        headline: 'Portrait',
                        breakpoint: "xxl",
                        hidden: !this.event,
                    }, {
                        headline: 'Research Report',
                        breakpoint: "xxl",
                        hidden: !this.event,
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ].filter(el => el !== null),
                pagination: this.pageSize,
                search: this.showFilters,
                select: this.enableSelect,
                selectPosition: "pre",
                selectAll: this.enableSelect,
                prettySelect: this.enableSelect,
                pageSortSelect: this.enableSelect,
                ajaxUrl: '/api/companies/list?' + this.buildQueryString(params),
                searchPlaceholder: "Search (Full-text or IDs)",
                requestMethod: "POST",
                storeState: true,
            };
        },
        userIdFinal() {
            return this.filter.onlyOwnCompanies && this.userInfo ? this.userInfo.id : this.userId
        },
        computedFilters: {
            async set(val) {
                // Select filters:
                this.filter.privateCompanies = val.isPrivate;
                this.filter.companyRoles = val.companyRoles;
                this.filter.exchanges = val.exchanges;
                this.filter.sectors = val.sectors;
                this.filter.industries = val.industries;
                this.filter.companyCharacteristics = val.companyCharacteristics;
                this.filter.cities = val.cities;
                this.filter.states = val.states;
                this.filter.countries = val.countries;
                this.filter.leadBankers = val.leadBankers;
                this.filter.bankers = val.secondaryBankers;
                this.filter.analysts = val.analysts;
                this.filter.marketListMembers = val.marketListMembers;
                this.filter.corporateAccessManagers = val.corporateAccessManagers;

                if (val.companyGroupIds) {
                    const newVal = val.companyGroupIds[0];
                    let listIdToFetch = newVal;

                    // If Company List ID was provided as property - take it!
                    if (this.companyListId) {
                        listIdToFetch = this.companyListId;
                    }

                    if (!this.filter.companyGroup || newVal !== this.filter.companyGroup.id) {
                        const response = await this.$axios.get(`/api/company_groups/${listIdToFetch}`);
                        this.filter.companyGroup = response.data
                    }
                }


                if (val.contactGroupIds) {
                    const newVal = val.contactGroupIds[0];
                    if (!this.filter.contactGroup || newVal !== this.filter.contactGroup.id) {
                        const response = await this.$axios.get(`/api/contact_list/${newVal}`);
                        this.filter.contactGroup = response.data
                    }
                }

                if (val.eventId) {
                    const newVal = val.eventId;
                    if (!this.filter.event || newVal !== this.filter.event.id) {
                        const response = await this.$axios.get(`/api/events/${newVal}`);
                        this.filter.event = response.data
                    }
                }

                this.filter.eventRole = val.eventRole;

                this.filter.needsAttention = val.needsAttention;
                this.filter.isRestricted = val.isRestricted;

                // Switch filters:
                this.filter.onlyFavoriteCompanies = val.onlyFavoriteCompanies;
                this.filter.leadBankerCompanies = val.leadBankerCompanies;
                this.filter.secondaryBankerCompanies = val.secondaryBankerCompanies;
                this.filter.onlyStaffedCompanies = val.onlyStaffedCompanies;
                this.filter.onlyNotStaffedCompanies = val.onlyNotStaffedCompanies;
                this.filter.onlyCoveredCompanies = val.onlyCoveredCompanies;
                this.filter.onlyMarketListCompanies = val.onlyMarketListCompanies;
                this.filter.excludeInactive = val.excludeInactive;

                // Other filters:
                this.filter.companyIds = val.companyIds;
                this.filter.area = val.area;

                // Tab filters
                this.filter.classification = val.classification;
            },
            get() {
                return {
                    // Select filters:
                    isPrivate: this.filter.privateCompanies,
                    companyRoles: this.filter.companyRoles,
                    exchanges: this.filter.exchanges,
                    sectors: this.filter.sectors,
                    industries: this.filter.industries,
                    companyCharacteristics: this.filter.companyCharacteristics,
                    cities: this.filter.cities,
                    states: this.filter.states,
                    countries: this.filter.countries,
                    leadBankers: this.filter.leadBankers,
                    secondaryBankers: this.filter.bankers,
                    analysts: this.filter.analysts,
                    marketListMembers: this.filter.marketListMembers,
                    corporateAccessManagers: this.filter.corporateAccessManagers,
                    companyGroupIds: this.filter.companyGroup ? [this.filter.companyGroup.id] : null,
                    contactGroupIds: this.filter.contactGroup ? [this.filter.contactGroup.id] : null,
                    eventId: this.filter.event ? this.filter.event.id : null,
                    eventRole: this.filter.eventRole,
                    needsAttention: this.filter.needsAttention,
                    isRestricted: this.filter.isRestricted,
                    meetingWithContact: this.meetingWithContact?.id,
                    // Switch filters:
                    onlyFavoriteCompanies: this.filter.onlyFavoriteCompanies,
                    userId: this.userIdFinal,
                    leadBankerCompanies: this.filter.leadBankerCompanies,
                    secondaryBankerCompanies: this.filter.secondaryBankerCompanies,
                    onlyStaffedCompanies: this.filter.onlyStaffedCompanies,
                    onlyNotStaffedCompanies: this.filter.onlyNotStaffedCompanies,
                    onlyCoveredCompanies: this.filter.onlyCoveredCompanies,
                    onlyMarketListCompanies: this.filter.onlyMarketListCompanies,
                    excludeInactive: this.filter.excludeInactive,
                    // Other filters:
                    companyIds: this.filter.companyIds,
                    area: this.filter.area,
                    relevantForUserIdWithoutML: this.relevantForUserId,
                    // Tab filters
                    classification: this.filter.classification,
                    hash: this.companiesHash,

                }
            }
        },
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        companyIds: structuredClone(this.preSelectedRowIds),
                    }
                case "page":
                    return {
                        companyIds: this.ajaxRows.map(function (row) {
                            return row.company_id;
                        })
                    }
                case "filtered":
                default:
                    if (this.searchQuery) {
                        return {...this.computedFilters, 'search_term': this.searchQuery}
                    }
                    return this.computedFilters;
            }
        },
        noteToAddType() {
            return "COMPANY"
        },
        exportColumnsUrl() {
            return `/api/companies/excel_export_columns?${this.buildQueryString({
                includeEvent: this.filter.event !== null, includeGroup: this.filter.companyGroup !== null
            })}`;
        }
    },
    watch: {
        computedFilters: {
            handler() {
                this.relevantChangesHappened = true;
            },
            deep: true,
        },
        "filter.event": {
            handler() {
                if (!this.filter.event) {
                    this.filter.eventRole = null;
                    this.filter.meetingWithContact = null;
                }
            }
        },
        "filter.leadBankerCompanies": {
            handler() {
                if (this.filter.leadBankerCompanies === true) {
                    this.filter.secondaryBankerCompanies = false;
                }
            }
        },
        "filter.secondaryBankerCompanies": {
            handler() {
                if (this.filter.secondaryBankerCompanies === true) {
                    this.filter.leadBankerCompanies = false;
                }
            }
        },
        "filter.onlyStaffedCompanies": {
            handler() {
                if (this.filter.onlyStaffedCompanies === true) {
                    this.filter.onlyNotStaffedCompanies = false;
                }
            }
        },
        "filter.onlyNotStaffedCompanies": {
            handler() {
                if (this.filter.onlyNotStaffedCompanies === true) {
                    this.filter.onlyStaffedCompanies = false;
                }
            }
        },
        searchQuery(val) {
            const onlyIds = val && /^[\d\s,]+$/.test(val);
            if (onlyIds) {
                this.filter.companyIds = val.replaceAll(" ", ",").split(",").filter(x => x);
            } else {
                this.filter.companyIds = null;
            }
        },
        tableLoaded(val) {
            if (val && this.filterOptions.countries.length === 0) {
                this.loadCompanyFilterOptions();
                this.loadMostUsedCompanyGroups();
                this.loadMostUsedContactGroups()
            }
        },
        preSelectedRowIds: {
            handler(val) {
                this.$emit("input", structuredClone(val));

                if (this.isLocalStorageAvailable()) {
                    localStorage.setItem(
                        `${this.identifier}-pre-selected-row-ids`,
                        JSON.stringify(this.preSelectedRowIds),
                    );
                }
            },
            deep: true
        },
        selectedRows: {
            handler(rows) {
                if (!this.relevantChangesHappened && this.ajaxRows) {

                    for (let i = 0; i < rows.length; i++) {
                        if (!this.preSelectedRowIds.includes(rows[i].company_id)) {
                            this.preSelectedRowIds.push(rows[i].company_id);
                        }
                    }

                    for (let j = 0; j < this.ajaxRows.length; j++) {
                        const id = this.ajaxRows[j].company_id;

                        const index = this.preSelectedRowIds.indexOf(id);

                        if (!rows.map(r => r.company_id).includes(id) && index !== -1) {
                            this.preSelectedRowIds.splice(index, 1);
                        }
                    }
                }
            },
            immediate: true,
        },
        nextRoute(route) {
            if (this.$route.path !== route) {
                this.showTable = false;
            }
        }
    },

    created() {
        // necessary to make reset filter work
        this.defaultFilter = structuredClone(this.filter);

        const additionalFilters = {};

        if (this.isLocalStorageAvailable()) {
            const preSelectedRowIds = localStorage.getItem(
                `${this.identifier}-pre-selected-row-ids`
            );

            if (preSelectedRowIds) {
                this.preSelectedRowIds = JSON.parse(preSelectedRowIds);
            }
        }

        // Handle filters coming from outside
        const promises = [];
        if (this.initialFilters) {
            Object.keys(this.initialFilters).forEach(key => {
                switch (key) {
                    case "staffedOnly":
                        additionalFilters.onlyStaffedCompanies = true;
                        break;
                    case "coveredOnly":
                        additionalFilters.onlyCoveredCompanies = true;
                        break;
                }
            })
        }
        if (this.companyListId) {
            const a = this.$axios.get(`/api/company_groups/${this.companyListId}`).then((res) => {
                additionalFilters.companyGroup = res.data
            });
            promises.push(a);
        }

        // Save current filter as default filter when companyGroup was loaded
        Promise.all(promises).then(() => {
            this.$nextTick(() => {
                this.filter = {...this.defaultFilter, ...this.filter, ...additionalFilters};
            });
        });
    },
    beforeDestroy() {
        this.showTable = false;
    },
    mounted() {
        this.initializeOptionButtonTooltips();
    },
    methods: {

        isLocalStorageAvailable() {
            const testLocalStorage = "test-local-storage";
            try {
                localStorage.setItem(testLocalStorage, testLocalStorage);
                localStorage.removeItem(testLocalStorage);
                return true;
            } catch (e) {
                console.warn(
                    "Option storeState was deactivated automatically because local storage is not available!"
                );
                return false;
            }
        },
        loadMostUsedCompanyGroups() {
            return this.$axios.get("/api/company_groups/most_used").then(response => {
                this.mostUsedCompanyGroups = response.data["hydra:member"];
            });
        },
        loadMostUsedContactGroups() {
            return this.$axios.get("/api/contact_lists/most_used").then(response => {
                this.mostUsedContactGroups = response.data["hydra:member"];
            });
        },
        notesUpdated() {
            this.$emit("updated")
            this.closeModal(this.modal);
        },
        onRowsUpdated(data) {
            this.relevantChangesHappened = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowIds.map(id => {
                    return {
                        key: "company_id",
                        value: id
                    }
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
            this.tableLoaded = true;
            if (this.userInfo && ((this.userId === this.userInfo.id) || (this.relevantForUserId === this.userInfo.id))) {
                this.$emit('company-list-loaded', data);
            }
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },

        async requestDeleteStaffing(id) {
            this.companyRequestStaffing = id;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.deleteStaffingRequestModal);
        },
        async requestStaffing(id) {
            this.companyRequestStaffing = id;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.staffingRequestModal);
        },
        cancelRequestStaffing() {
            this.companyRequestStaffing = null;
        },

        updateSearchQuery(searchQuery) {
            this.relevantChangesHappened = true;
            this.searchQuery = searchQuery;
        },
        downloadCompanies() {
            this.downloadQuery = {
                search: this.filter.companyIds ? '' : this.searchQuery,
                filters: this.computedFilters,
                sort: this.sortOrder,
            };

            this.openModal(this.$refs.downloadCompaniesModal);
        },
        deleteCompany(id) {
            this.companyToDelete = id;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.deleteCompanyModal);
            });
        },
        removeCompanyFromEvent(id) {
            this.companyToRemoveFromEventId = id;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.removeFromEventModal);
            });
        },
        eventCompaniesUpdated() {
            this.$emit('event-companies-updated');
            this.closeModal(this.modal);
        },
        editCompany(id) {
            this.$axios.get("/api/companies/" + id).then((response) => {
                this.company = response.data;
                this.$nextTick(() => {
                    this.modal = this.openModal(this.$refs.editCompanyModal);
                });
            });
        },
        doDeleteCompany(id) {
            this.loading = true;

            this.$axios.delete("/api/companies/" + id).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Deleted",
                    message: "Company deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.companiesUpdated = moment().valueOf()
            });
        },
        doReplaceCompany(data) {
            this.loading = true;

            this.$axios.post("/api/companies/merge_delete", {
                toDeleteId: data.companyId,
                replaceById: data.replaceId,
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Replace",
                    message: "Company replaced",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.companiesUpdated = moment().valueOf()
            });
        },
        cancelDeleteCompany() {
            this.companyToDelete = null
        },
        addCompany() {
            this.resetAddCompany();
            this.$axios.get("/api/companies/empty")
                .then((response) => {
                    this.newCompany = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.addCompanyModal);
                    });
                });
        },
        addCompaniesToSessions() {
            this.pushToSessions = +new Date();

            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.pushCompaniesToSessionsModal);
            });
        },
        companyUpdated() {
            this.companiesUpdated = moment().valueOf();
            this.closeModal(this.modal);
        },
        loadCompanyFilterOptions() {
            this.$axios.get("/api/companies/filter_options")
                .then((response) => {
                    this.filterOptions = response.data;
                }).finally(() => {
                    this.filterOptions.companyCharacteristics = this.filterOptions.companyCharacteristics.map(c => {
                        if (c.companyCharacteristicGroup.title) {
                            return {
                                id: c.id,
                                title: `${c.companyCharacteristicGroup.title} » ${c.title}`,
                            }
                        } else {
                            return {
                                id: c.id,
                                title: c.title,
                            }
                        }
                    })
                });
        },
        resetFilters() {
            this.filter = structuredClone(this.defaultFilter);
            this.meetingWithContact = null;
            this.$refs.quintable.setSearchQuery("")
            this.$refs.map.resetMap();

            this.$nextTick(() => {
                // this.mapKey = this.generateUUID();
                this.companiesUpdated = moment().valueOf();
            });
        },
        resetAddCompany() {
            this.addCompanyReset = moment().valueOf();
        },
        showEditForm() {
            this.modal = this.openModal(this.$refs.editCompanyModal);
        },
        showCoverage(userId) {
            this.coverageUserId = userId;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.userCoverageModal);
            });
        },
        sortUpdate(order) {
            this.sortOrder = order;
        },
        onPageChange() {
            this.relevantChangesHappened = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().valueOf();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.companyBulkOperationModal)
            })
        },
        onBulkSubmitted() {
            this.updateTable();
            this.closeModal(this.bulkModal);
        },

        updateTable() {
            this.companiesUpdated = moment().valueOf();
        },
        async addNote(id) {
            await this.$axios.get("/api/companies/" + id).then((res) => {
                this.noteCompany = res.data;
            });

            const prom = new Promise((resolve) => {
                if (!this.noteTemplate) {
                    this.$axios.get("/api/notes/empty")
                        .then((response) => {
                            this.noteTemplate = response.data;
                            resolve()
                        });
                } else {
                    resolve();
                }
            });
            prom.then(() => {
                this.noteToAdd = Object.assign({}, this.noteTemplate);
                this.noteToAdd.company = this.noteCompany
                this.noteToAdd.type = "COMPANY"
                this.$nextTick(this.openAddModal);
            })
        },
        openAddModal() {
            this.modal = this.openModal(this.$refs.addNoteModal, {
                backdrop: "static",
                keyboard: false,
            });
        },
        async openCompanyFeedback(cell) {
            this.feedbackCellToEdit = cell;
            await this.$nextTick;
            this.$refs.companyFeedbackModal.resetWorkingCopy();
            this.modal = this.openModal(this.$refs.companyFeedbackModal);
        },
        feedbackUpdateHandler(eventHasCompany) {
            this.feedbackCellToEdit.feedback = eventHasCompany.feedback;
            this.feedbackCellToEdit = null;
            this.closeModal(this.modal);
        },
        // areaUpdated(area) {
        //     this.area = area;
        // },
        optional(value) {
            return (value || 'n/a')
        },
        openCompanyEventInformationModal(companyId, eventId) {
            this.companyIdsForInfo = [companyId];
            this.eventIdForInfo = eventId;
            this.$nextTick(() => {
                this.openModal(this.$refs.copyCompanyInformationModal);
            });
        },
        removeCompanyFromList(companyId) {
            this.$emit('remove-company-from-list', companyId);
        },
        rejectCompany(eventHasCompanyId) {
            this.companyRejectionInProgress = true;
            this.$axios.post(`/api/events/reject_company/${eventHasCompanyId}`).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Company rejected",
                    message: "The company has been removed from the event, and all participants have been marked as not attending.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
                this.$emit('event-companies-updated');
            }).finally(() => {
                this.companyRejectionInProgress = false;
            });
        },
    }
}
