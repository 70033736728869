import { render, staticRenderFns } from "./account-signatories.vue?vue&type=template&id=4e7227b4&scoped=true"
import script from "./account-signatories.vue?vue&type=script&lang=js"
export * from "./account-signatories.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7227b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3295261676/src/web/components/loading-spinner.vue').default})
