


import SheetBook from "../bulk-operations/sheet-book.vue";
import SaveButton from "../../save-button.vue";

export default {
    name: "CompanySheetBookModal",
    components: {SaveButton, SheetBook},
    props: {
        company: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            finished: false,
        }
    },
    methods: {
        reset() {
            this.$refs.sheetBook.reset();
        },
    }
}
